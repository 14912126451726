<template>
  <!-- Use levelLeftMobile class so that on mobile the unit label stays on same line -->
  <b-field :label="label" horizontal :type="{ 'is-danger': errors[0] }" :message="errors[0] || message">
    <div class="levelLeftMobile">
      <slot>
        <b-numberinput
          :value="value"
          @input="v => $emit('input', v)"
          :controls="false"
          required
          min="0"
          max="100"
          class="inputFixedWidth-1"
        />
      </slot>
      <p v-if="suffix" class="control unitLabel">{{ suffix }}</p>
    </div>
  </b-field>
</template>

<script>
export default {
  props: {
    label: { type: String, required: false },
    suffix: { type: String, required: false },
    message: { type: String, required: false },
    errors: { default: () => [] },
    value: { type: [String, Number], required: false }
  }
};
</script>
