const FrequencyEnum = {
  OneOff: 1,
  OnceAMonth: 2,
  OnceAWeek: 3,
  TwiceAWeek: 4,
  OnceADay: 5,
  TwiceADay: 6,
  ThreeTimesADay: 7,
  Every6Hours: 8,
  Every4Hours: 9,
  Every2Hours: 10,
  Hourly: 11,
  Every30Mins: 12
};

const FrequencyLookup = [
  "",
  "One Off",
  "Once a Month",
  "Once a Week",
  "Twice a Week",
  "Once a Day",
  "Twice a Day",
  "Three times a Day",
  "Every 6 Hours",
  "Every 4 Hours",
  "Every 2 Hours",
  "Hourly",
  "Every 30 Minutes"
];

const MonthFrequency = {
  Start: 1,
  End: 2,
  SameDate: 3
};

export default {
  Enum: FrequencyEnum,
  SelectLookup: [
    { id: FrequencyEnum.OneOff, value: FrequencyLookup[FrequencyEnum.OneOff] },
    { id: FrequencyEnum.OnceAMonth, value: FrequencyLookup[FrequencyEnum.OnceAMonth] },
    { id: FrequencyEnum.OnceAWeek, value: FrequencyLookup[FrequencyEnum.OnceAWeek] },
    { id: FrequencyEnum.TwiceAWeek, value: FrequencyLookup[FrequencyEnum.TwiceAWeek] },
    { id: FrequencyEnum.OnceADay, value: FrequencyLookup[FrequencyEnum.OnceADay] },
    { id: FrequencyEnum.TwiceADay, value: FrequencyLookup[FrequencyEnum.TwiceADay] },
    { id: FrequencyEnum.ThreeTimesADay, value: FrequencyLookup[FrequencyEnum.ThreeTimesADay] },
    { id: FrequencyEnum.Every6Hours, value: FrequencyLookup[FrequencyEnum.Every6Hours] },
    { id: FrequencyEnum.Every4Hours, value: FrequencyLookup[FrequencyEnum.Every4Hours] },
    { id: FrequencyEnum.Every2Hours, value: FrequencyLookup[FrequencyEnum.Every2Hours] },
    { id: FrequencyEnum.Hourly, value: FrequencyLookup[FrequencyEnum.Hourly] },
    { id: FrequencyEnum.Every30Mins, value: FrequencyLookup[FrequencyEnum.Every30Mins] }
  ],
  Lookup: FrequencyLookup,
  MonthFrequency: MonthFrequency,
  MonthSelect: [
    { id: MonthFrequency.Start, value: "Start of Month" },
    { id: MonthFrequency.End, value: "End of Month" },
    { id: MonthFrequency.SameDate, value: "Same Date each Month" }
  ]
};
