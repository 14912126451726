<template>
  <div>
    <LabelSuffix label="One Off" :suffix="days">
      <b-numberinput
        expanded
        :value="value[orgConfigEnum.Freq_Default_OneOff].value"
        @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_OneOff)"
        placeholder="Due in X Days"
        :controls="false"
        required
        :disabled="disabled"
        min="0"
        max="30"
        class="inputFixedWidth-1"
      />
    </LabelSuffix>

    <b-field label="Once a Month" horizontal>
      <EnumDropDown
        :value="value[orgConfigEnum.Freq_Default_OnceAMonth].value"
        @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_OnceAMonth)"
        :data="MonthSelect"
        placeholder="When in the month"
        required
        :disabled="disabled"
      />
    </b-field>
    <b-field label="Once a Week" horizontal>
      <WeekDaySelect
        :value="value[orgConfigEnum.Freq_Default_OnceAWeek].value"
        @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_OnceAWeek)"
        :disabled="disabled"
      />
    </b-field>
    <b-field label="Twice a Week" horizontal>
      <div class="is-flex">
        <WeekDaySelect
          :value="value[orgConfigEnum.Freq_Default_TwiceAWeek].value"
          @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_TwiceAWeek)"
          :disabled="disabled"
        />
        <WeekDaySelect
          :value="value[orgConfigEnum.Freq_Default_TwiceAWeek].value2"
          @input="v => updateTime(v, 'value2', orgConfigEnum.Freq_Default_TwiceAWeek)"
          :disabled="disabled"
        />
      </div>
    </b-field>
    <b-field label="Once a Day" horizontal>
      <HourSelect
        :value="value[orgConfigEnum.Freq_Default_OnceADay].value"
        @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_OnceADay)"
        :disabled="disabled"
      />
    </b-field>
    <b-field label="Twice a Day" horizontal>
      <div class="is-flex">
        <HourSelect
          :value="value[orgConfigEnum.Freq_Default_TwiceADay].value"
          @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_TwiceADay)"
          :disabled="disabled"
        />
        <HourSelect
          :value="value[orgConfigEnum.Freq_Default_TwiceADay].value2"
          @input="v => updateTime(v, 'value2', orgConfigEnum.Freq_Default_TwiceADay)"
          :disabled="disabled"
        />
      </div>
    </b-field>
    <b-field label="Three times a Day" horizontal>
      <div class="is-flex">
        <HourSelect
          :value="value[orgConfigEnum.Freq_Default_ThreeTimesADay].value"
          @input="v => updateTime(v, 'value', orgConfigEnum.Freq_Default_ThreeTimesADay)"
          :disabled="disabled"
        />
        <HourSelect
          :value="value[orgConfigEnum.Freq_Default_ThreeTimesADay].value2"
          @input="v => updateTime(v, 'value2', orgConfigEnum.Freq_Default_ThreeTimesADay)"
          :disabled="disabled"
        />
        <HourSelect
          :value="value[orgConfigEnum.Freq_Default_ThreeTimesADay].value3"
          @input="v => updateTime(v, 'value3', orgConfigEnum.Freq_Default_ThreeTimesADay)"
          :disabled="disabled"
        />
      </div>
    </b-field>
  </div>
</template>

<script>
import EnumDropDown from "@/components/EnumDropDown";
import LabelSuffix from "@/components//LabelSuffix";
import FrequencyEnum from "@/enums/frequency.js";
import WeekDaySelect from "./WeekDaySelect.vue";
import HourSelect from "./HourSelect.vue";
import OrgConfig from "@/enums/orgconfig";

export default {
  components: { EnumDropDown, WeekDaySelect, HourSelect, LabelSuffix },
  props: {
    value: {},
    disabled: { type: Boolean }
  },
  data() {
    return {
      orgConfigEnum: OrgConfig.Enum
    };
  },
  computed: {
    days() {
      return this.value[OrgConfig.Enum.Freq_Default_OneOff].value === 1 ? "day" : "days";
    }
  },
  methods: {
    updateTime(value, key, index) {
      // this.$emit("updateTime", { index: index, value: { ...this.value[index], key: v } });
      this.$emit("updateTime", { index: index, value: value, key: key });
    }
  },
  created() {
    // setting in num like this, in created rather than data, means its not reactive and it doesn't need to be
    this.FrequencyEnum = FrequencyEnum.Enum;
    this.MonthSelect = FrequencyEnum.MonthSelect;
  }
};
</script>
