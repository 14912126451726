<template>
  <b-select :value="value" @input="v => $emit('input', v)" required :disabled="disabled" class="hourSelect">
    <option v-for="option in hours" :value="option" :key="option"> {{ formatHour(option) }} </option>
  </b-select>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: { type: Number },
    disabled: { type: Boolean }
  },
  data() {
    return {
      hours: _.range(0, 24)
    };
  },
  methods: {
    formatHour(h) {
      var s = (h < 10 ? "0" : "") + h;
      return s + ":00";
    }
  }
};
</script>

<style>
.hourSelect {
  width: 70px;
}
</style>
