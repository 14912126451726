<template>
  <XyeaFormEdit
    icon="cog"
    url="orgconfig/"
    entityName="Configuration"
    :form="defaults"
    :id="orgId"
    :loadOnOpen="false"
    @mapServerData="mapServerData"
    @update="updateConfig"
  >
    <CollapseCard title="Intervention Frequency Defaults" name="freq" :open.sync="freqOpen">
      <FreqTimeOfDayDefaults :value="defaults" @updateTime="updateTime" />
    </CollapseCard>

    <CollapseCard title="Weight Loss Assessments" name="obs" :open.sync="obsOpen">
      <LabelSuffix
        label="Recent Weight Limit"
        message="Recent weight must be within this number of days"
        suffix="days"
        v-model="defaults[configEnum.Obs_RecentWeightDays].value"
      />
      <ValidationProvider name="weightMin">
        <LabelSuffix
          label="Previous Weight Min"
          message="Automatic weight loss calculation done only if previous weight is greater than this number of days"
          suffix="days"
          v-model="defaults[configEnum.Obs_WeightLossCalc_Previous_Min].value"
        />
      </ValidationProvider>
      <ValidationProvider name="weightMax">
        <LabelSuffix
          label="Previous Weight Max"
          message="Automatic weight loss calculation done only if previous weight is greater less this number of days"
          suffix="days"
          v-model="defaults[configEnum.Obs_WeightLossCalc_Previous_Max].value"
        />
      </ValidationProvider>
      <ValidationProvider rules="minmax:@weightMin,@weightMax" v-slot="{ errors }">
        <LabelSuffix
          label="Previous Weight Preferred"
          message="Automatic weight loss will used previous weight that is as close to this number of days from the current value"
          :errors="errors"
          suffix="days"
          v-model="defaults[configEnum.Obs_WeightLossCalc_Previous_Preferred].value"
        />
      </ValidationProvider>
    </CollapseCard>
  </XyeaFormEdit>
</template>

<script>
import CollapseCard from "@/components/CollapseCard.vue";
import FreqTimeOfDayDefaults from "@/components/careplans/FreqTimeOfDayDefaults.vue";
import XyeaFormEdit from "@/components/XyeaFormEdit.vue";
import CheckDirty from "@/mixins/checkDirty";
import LabelSuffix from "@/components/LabelSuffix.vue";
import OrgConfig from "@/enums/orgconfig.js";

export default {
  mixins: [CheckDirty],
  components: { XyeaFormEdit, CollapseCard, FreqTimeOfDayDefaults, LabelSuffix },
  data() {
    return {
      freqOpen: true,
      obsOpen: true,
      orgId: this.$store.getters.orgId,
      defaults: this.$store.getters["orgconfig/defaults"],
      configEnum: OrgConfig.Enum
    };
  },

  methods: {
    updateTime(v) {
      this.defaults[v.index][v.key] = v.value;
    },
    updateConfig(data) {
      // save back to store
      this.$store.dispatch("orgconfig/setDefaults", data);
    }
  }
};
</script>
