<template>
  <b-select :value="value" @input="v => $emit('input', v)" required :disabled="disabled" class="weekSelect">
    <option v-for="option in weekdays" :value="option" :key="option">
      {{ weekDay(option) }}
    </option>
  </b-select>
</template>

<script>
import _ from "lodash";
import Dates from "@/common/dates";

export default {
  props: {
    value: { type: Number },
    disabled: { type: Boolean }
  },
  data() {
    return {
      weekdays: _.range(0, 7)
    };
  },
  created() {
    this.weekDay = Dates.weekDay;
  }
};
</script>

<style>
.weekSelect {
  width: 110px;
}
</style>
