export default {
  methods: {
    async handleSubmitWithErrorScroll() {
      if (await this.$refs.observer.validate()) return this.submit();

      // validateWithInfo can give is the field that is error, but to get control from that component we would need
      // to name each ValidationProvider and provide a matching id for a corresponding control
      // const validInfo = await this.$refs.observer.validateWithInfo();
      // const firstField = Object.values(validInfo.fields).find(f => f.failed);
      // if (firstField) {
      //   var ctrl = document.getElementById(firstField.id);
      //   if (ctrl) ctrl.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      // }

      // This searches the DOM for the .help.is-danger class on the error text and scrolls to that
      var errorControl = document.querySelector(".help.is-danger");
      if (errorControl) errorControl.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }
};
